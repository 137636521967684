<template>
    <div>
        <app-page-header></app-page-header>
        <v-container  grid-list-xl class="box">
            <v-layout row wrap align-center justify-center fill-height v-for="(item, index) in occupancyData" :key="index">
                <v-flex xs12 sm12 md10 lg8 xl6>
                    <parking-lot-occupancy :avtId="item.id" :itemData="item" ></parking-lot-occupancy>
                </v-flex>
            </v-layout>

        </v-container>
    </div>
</template>

<script>
const ParkingLotOccupancy = () => import('@/components/ParkingLotOccupancy.vue')

export default {
    components: {
        ParkingLotOccupancy
    },

    data: () => ({
        parking_occupancy_items: [],
        parking_occupancy_data: [],
        interval1: null
    }),

    methods: {
        fetchData() {
            this.$store.commit('SET_PROGRESS', true)
            this.$store.dispatch('PARKING_OCCUPANCY_DATA', {parking_lots: this.parking_occupancy_items})
            .then(response => {
                //window.console.log(response.data)
                this.parking_occupancy_data = response.data.data
                //window.console.log(this.parking_occupancy_items)
            })
            .catch(err => {
                window.console.log("error")
            })
            .finally(() => {
                this.$store.commit('SET_PROGRESS', false)
            })
        }
    },

    computed: {
        occupancyData() {
            return this.parking_occupancy_data
        }
    },

    created() {
        //window.console.log(this.$route.name)
        this.$store.dispatch('ROUTE_NAME', this.$route.name)

        this.parking_occupancy_items = process.env.VUE_APP_DATA_PARKING_OCCUPANCY_ITEMS
        this.parking_occupancy_items = this.parking_occupancy_items.split(",");

        this.fetchData();
        this.interval1 = setInterval(() => {
            this.fetchData();
        }, process.env.VUE_APP_DATA_PARKING_OCCUPANCY_INTERVAL || 180000)
    },

    mounted() {

    },

    destroyed() {
        clearInterval(this.interval1)
    }
}

</script>